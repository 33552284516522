.uiform {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding: 1rem 2rem 1rem 2rem; 
    max-width: 100%;
    @media screen and (max-width: 650px) {
        padding: 1rem;
    }
    .contentText{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 25px;
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      align-items: center;
      @media screen and (max-width: 650px) {
        grid-template-columns: 1fr;
      }
      div.MuiAutocomplete-root.MuiAutocomplete-fullWidth.MuiAutocomplete-hasPopupIcon{
        padding-top: 0;
      }
      .videoContainer{
        video{
          max-width: 100%;
          margin-top: 10px;
        }
        .videoBtnContainer{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;
        }
      }
    }
    .checkContenair{
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        grid-gap: 10px;
        .uicheckbox {
            padding: 8.5px 14px;
            @media screen and (max-width: 650px) {
              width: 100%;
            }
        }
    }
    .textAreaContenair{
      width: 100%;
      textarea{
        width: 100%;    
        min-height: 22rem;
        max-height: 22rem;
        background-color: rgb(255, 255, 255);
        border: 2px solid #e1e4e8;
        border-radius: 8px;
        font-family: "Roboto", "Helvetica";
        padding:8.5px 14px;
      }
    }
    .selectContainer{
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: flex-start;
      align-self: baseline;
      max-width: 100%;
      overflow: hidden;
      h3{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .deleteElement{
          width: 20px;
          height: 20px;
          cursor: pointer;
          vertical-align: middle;
        }
      }
    }
    .fullWidth{
      text-align: center;
      grid-column: 1/span 2;
      display: flex;
      justify-content: space-around;
    }
  }

  .errorMessage{
    color: var(--red);
    text-align: center;
  }

  
.tabsContainer{
  button{
    padding: 15px 20px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 40px;
    background-color: var(--jra-blue);
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
    margin-right: 5px;
    border: 1px solid #ffffff;
    &.Mui-selected{
      color: #FFFFFFFF;
    }
    &:hover{
      color: var(--jra-blue);
      background-color: #ffffff;
      border: 1px solid var(--jra-blue);
    }
    &.selected{
      color: var(--jra-blue);
      background-color: #ffffff;
      border: 1px solid var(--jra-blue);
    }
  }
}

.tinyPopupSelectContainer{
  width: 100%;
  margin: 20px 0;
  padding: 0 10px;
}