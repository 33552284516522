.backgroundBlur {
    background-color: #ffffff7d;
    backdrop-filter: blur(5px);
    border: 1px solid var(--lighter-grey);
}

.postCategory {
    margin: 0 3px;
    padding: 3px 6px;
    font-weight: 500;
    border: 1px solid;
    border-radius: 5px;
    text-transform: uppercase;
}

.styledCircularProgress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}

// common for home and allPosts page

.block,
.post {
    position: relative;
    padding: 20px 25px;
    background-color: #FFF;
    border-radius: 15px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            margin: 0;
            font-size: 15px;
            text-decoration: underline;
        }
    }
}

.block {
    border: 2px solid var(--lighter-grey);
}

.post {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 30px;

    .postContent {
        display: flex;
        flex-direction: column;
        gap: 1vh;

        .header {

            .tagsBox {
                display: flex;
                gap: .5vw;
                align-items: center;

                span {
                    &.newsCategory {
                        padding: 5px 10px;
                        font-size: 10px;
                        border: 1px solid;
                        border-radius: 15px;
                        text-transform: uppercase;
                    }

                    &.date {
                        font-size: 12px;
                        color: #A5A5A5;
                    }
                }
            }
        }

        .postBody {
            display: flex;
            flex-direction: column;
            gap: 1vh;

            img {
                height: 100%;
                width: 100%;
                margin: 0;
                object-fit: cover;
            }

            .imageBox {
                position: relative;

                img {
                    &.expanded {
                        max-height: 450px;
                        height: fit-content;
                    }
                }
            }

            .postText {

                h3,
                h4,
                .content {
                    margin: 0;
                }

                h3 {
                    font-size: 14px;
                }

                h4,
                .date {
                    font-size: 13px;
                    font-weight: 500;
                }

                .content {
                    margin: 0;
                    font-size: 11px;
                }

                .description {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    margin: 0;
                }

                .button {
                    margin: 7px 0 0 7px !important;
                }
            }
        }
    }
}

.likeNumberBox {
    position: absolute;
    top: 12px;
    left: 15px;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    z-index: 1;

    .likeButton,
    .likeOverlay {
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    .likeButton {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--light-grey);
        box-shadow: 1px 1px 1px #00000052;
        transition: .3s;

        &:hover {
            box-shadow: none;
            transform: scale(1.1);
            transition: .3s;
        }

        .likeOverlay {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background-color: var(--jra-blue);
            transform: scale(0);
            transition: all .4s;
            z-index: 0;
        }

        svg {
            width: auto;
            color: #fff;
            position: relative;
            z-index: 1;

            &.isLiked {
                height: 18px;
                display: none;

            }

            &.notLiked {
                height: 22px;
                display: block;
            }
        }

        &.isActive {
            opacity: 1;

            .likeOverlay {
                transform: scale(1);
            }

            svg {
                &.isLiked {
                    display: block;
                }

                &.notLiked {
                    display: none;
                }
            }
        }

        @keyframes bouncy {

            from,
            to {
                transform: scale(1, 1);
            }

            25% {
                transform: scale(0.9, 1.1);
            }

            50% {
                transform: scale(1.1, 0.9);
            }

            75% {
                transform: scale(0.95, 1.05);
            }
        }

        .bouncy {
            -webkit-animation: bouncy 0.6s;
            animation: bouncy 0.6s;
            -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
        }
    }

    .likeNumber {
        color: #fff;
        text-shadow: 1px 1px 2px #000;
    }
}

.autoScroll {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    padding-right: 10px !important;

    .list {
        display: flex;
        flex-direction: column;
        gap: 2vh;
        margin: 0;
        padding: 0;

        .post {
            padding: 0;

            .header {
                h3 {
                    margin: 0;
                    font-size: 13px;
                }
            }

            img {
                height: 170px;
                border-radius: 10px;
            }

            .postText {
                position: absolute;
                right: 0;
                bottom: 0;
                min-width: 45%;
                margin: 1vh 1vw;
                padding: 1vh 1vw;
                font-size: 11px;
                border-radius: 10px;
                display: inline-block;

                h4 {
                    margin: 5px 0 10px;
                }

                p {
                    margin: 0;
                }

                .button {
                    float: right;
                }
            }

            hr {
                width: 100%;
                margin: 2vh 0 0 0;
                border: 0;
                border-top: 1px solid var(--light-grey);
            }
        }
    }
}

.youtubeBox {
    position: relative;
    height: 100%;

    .youtubeLogo {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 95px !important;
        width: auto !important;
        transform: translate(-50%, -50%);
        opacity: .7;
        filter: grayscale(100%) contrast(.8);

        &.expanded {
            display: none;
        }
    }

    img {
        height: 100%;
    }

    iframe {
        &.expanded {
            height: 510px;
        }
    }
}


// 



#homePage {
    @media screen and (max-width: 1200px) {
        height: fit-content;
    }

    >.content {
        display: flex;
        justify-content: center;
        gap: 10px;
        max-height: 100%;

        @media screen and (max-width: 1200px) {
            flex-direction: column;
            align-items: center;
            max-height: inherit;
        }


        .column {
            display: flex;
            flex-direction: column;
        }

        .row {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }

        #columnOne,
        #columnTwo {
            justify-content: flex-start;
            gap: 10px;

            .firstBlock {
                height: 583px;

                @media screen and (max-height: 900px) {
                    height: 55vh;
                }
                @media screen and (max-width: 1200px) {
                    height: 583px;
                }
            }

            .secondBlock {
                height: 225px;

                @media screen and (max-height: 900px) {
                    height: 180px;
                }

                @media screen and (max-width: 1200px) {
                    height: 225px;
                }
            }
        }

        #columnOne {
            width: 645px;

            @media screen and (max-width: 1400px) {
                width: 500px;
            }

            @media screen and (max-width: 1200px) {
                width: 90%;
            }

            @media screen and (max-width: 850px) {
                width: 100%;
            }

            .firstBlock {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 10px;
            }

            #postsCarousel {
                height: 370px;
                padding: 0;
                border: 2px solid #fff;
                z-index: 0;

                @media screen and (max-width: 1200px) {
                    min-height: inherit;
                    max-height: inherit;
                }

                >div {
                    height: 100%;
                    border-radius: 15px;

                    // previous + next
                    >div:nth-of-type(2),
                    >div:nth-of-type(3) {
                        padding: 0;
                        z-index: 1;

                        button {
                            margin: 0 10px 0;
                            padding: 3px;

                            svg {
                                height: 20px;
                                width: 20px;
                            }
                        }
                    }

                    // dots
                    >div:nth-of-type(4) {
                        position: inherit;
                        margin-top: -30px;
                        z-index: 1;
                    }

                    >div,
                    >div>div,
                    >div>div>div,
                    >div>div>div>div,
                    >div>div>div>div>div,
                    >div>div>div>div>div>.post,
                    >div>div>div>div>div>.post .postContent,
                    >div>div>div>div>div>.post .postContent .postBody,
                    >div>div>div>div>div>.post .postContent .postBody .imageBox,
                    >div>div>div>div>div>.post .postContent .postBody .imageBox,
                    >img {
                        max-height: 100% !important;
                        height: 100% !important;
                    }
                }

                .post {
                    padding: 0;
    
                    .postContent {
                        position: relative;
    
                        .postBody {
                            display: block;
    
                            .imageBox {
                                .likeNumberBox {
                                    top: inherit;
                                    bottom: 25px;
                                    left: 35px;
                                }
    
                                img {
                                    height: 100%;
                                    width: 100%;
                                    border-radius: 15px;
                                    object-fit: cover;
                                }
                            }
    
                            .postText {
                                position: absolute;
                                top: 0;
                                max-height: 27%;
                                width: 100%;
                                padding: 10px 15px;
    
                                @media screen and (max-width: 1200px) {
                                    padding: 15px 2vw;
                                }
    
                                @media screen and (max-width: 775px) {
                                    padding: 15px 4vw;
                                }
    
                                @media screen and (max-width: 550px) {
                                    min-height: 15%;
                                    padding: 15px 20px;
                                }

                                @media screen and (max-height: 900px) {
                                    min-height: 90px;
                                    max-height: 120px;
                                }
    
                                .postContent {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
    
                                    // titre + subtitle + description
                                    >div:first-of-type {
                                        width: 90%;
                                        text-wrap: pretty;
    
                                        .content {
                                            @media screen and (max-width: 560px) {
                                                display: none;
                                            }
                                        }
                                    }
    
                                    // date + btn
                                    >div:nth-of-type(2) {
                                        display: flex;
                                        flex-direction: column;
    
                                        p {
                                            text-align: right;
                                        }
    
                                        a {
                                            margin: 5px 0 0 !important;
                                        }
    
                                    }
                                }
                            }
                        }
                    }
    
                    &.singlePost {
                        .backgroundBlur {
                            max-height: 27% !important;
                        }
                        .likeNumberBox {
                            height: 35px !important;
                        }
                    }
                }
    
                .postBody {
                    .description {
                        .content {
                            font-size: 11px;
                        }
                    }
                }
            }

            .appsBlock {
                display: flex;
                flex-direction: column;
                gap: 10px;

                @media screen and (max-height: 900px) {
                    height: 180px;
                }
                @media screen and (max-width: 1200px) {
                    height: 225px;
                }

                h2 {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    margin: 0;
                    font-size: 14px;
                    text-transform: uppercase;
                    text-decoration: none;
                }

                ul {
                    display: flex;
                    justify-content: flex-start;
                    gap: 10px;
                    height: 131px;
                    max-width: 100%;
                    margin: 0;
                    padding: 0 0 12px;
                    text-align: center;
                    list-style-type: none;

                    li {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        align-items: center;
                        height: 110px;
                        min-width: 110px;
                        max-width: 110px;
                        border-radius: 10px;

                        @media screen and (max-width: 1400px) {
                            height: 90px;
                            min-width: 80px;
                            max-width: 80px;
                        }

                        @media screen and (max-width: 1200px) {
                            height: 120px;
                            min-width: 120px;
                            max-width: 120px;
                        }

                        a {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: center;
                            height: 100%;
                            width: 100%;
                            color: #000;

                            p {
                                position: absolute;
                                bottom: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 40%;
                                width: 100%;
                                margin: 0;
                                padding: 10px;
                                font-size: 11px;

                                @media screen and (max-width: 1400px) {
                                    font-size: 9px;
                                }

                                @media screen and (max-width: 1200px) {
                                    font-size: 12px;
                                }
                            }
                        }

                    }
                }

                &#userApps {
                    padding: 10px 25px 20px;

                    .header {
                        align-items: baseline;

                        .button {
                            margin: 8px;
                            transition: .1s;

                            &:hover {
                                margin: 0;
                                padding: 8px;
                                border-radius: 30px;
                                box-shadow: 0px 0px 4px 0px var(--light-grey);
                                transition: .1s;

                                svg {
                                    color: var(--grey);
                                    transition: .1s;
                                }
                            }

                            svg {
                                height: 20px;
                                color: var(--light-grey);
                                transition: .1s;
                            }
                        }
                    }

                    ul {
                        img,
                        svg {
                            position: absolute;
                            top: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: auto;
                            width: 50px;
                            margin-top: 15px;

                            @media screen and (max-width: 1200px) {
                                width: 60px;
                            }
                        }

                        svg {
                            height: 35px;
                            width: 35px;
                            margin-top: 30px;
                        }
                    }
                }

                &#docJra {
                    justify-content: space-between;

                    li {
                        a {

                            img,
                            p {
                                border-bottom-left-radius: 10px;
                                border-bottom-right-radius: 10px;
                            }

                            img {
                                height: 100%;
                                width: 100%;
                                border-top-left-radius: 10px;
                                border-top-right-radius: 10px;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }

        #columnTwo {
            width: 50%;

            @media screen and (max-width: 1400px) {
                width: 55%;
            }

            @media screen and (max-width: 1200px) {
                width: 90%;
            }

            @media screen and (max-width: 850px) {
                width: 100%;
            }

            .meteoblue {
                padding: 10px 20px;
            }

            .blockHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #FFF;

                @media screen and (max-width: 750px) {
                    h2 {
                        span {
                            &:first-of-type {
                                display: none;
                            }
                        }
                    }

                    .respoLaptop {
                        display: none;
                    }

                    .textButton {
                        min-height: 30px;
                        min-width: 30px;
                        padding: 5px !important;
                        justify-content: center;
                        border-radius: 30px;

                        svg {
                            height: 21px;
                            width: 21px;
                        }
                    }
                }

                .buttonBox {
                    display: flex;
                    gap: 1vw;

                    .button {
                        text-transform: uppercase;
                    }
                }
            }


            h2 {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 14px;
                text-transform: uppercase;
                text-decoration: none;
            }

            #postsList {
                gap: 0;
                padding: 0 !important;

                &.autoScroll {
                    max-height: 92%;
                }

                .blockHeader {
                    position: sticky;
                    top: 0;
                    flex-wrap: wrap;
                    gap: 1vw;
                    padding: 15px 20px;
                    z-index: 2;

                    h2 {
                        margin: 0;

                        .postCategory {
                            color: var(--green);
                            border-color: var(--green);
                        }
                    }
                }

                .postListContent {
                    display: flex;
                    flex-direction: column;
                    gap: 2vh;
                    align-items: center;
                    padding: 0 0 20px;

                    .list {
                        width: 100%;
                        padding: 0 20px;

                        #stats {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            gap: 15px;
                            margin-top: 2px;
                            padding: 10px 15px 15px;
                            border: 1px solid var(--light-grey);
                            border-radius: 10px;

                            @media screen and (max-width: 575px) {
                                width: fit-content;
                            }

                            .statsTitle {
                                margin: 0;
                                color: var(--grey);
                                font-size: 12px;
                                text-transform: uppercase;

                                svg {
                                    height: 20px;
                                    fill: #000;
                                    transform: rotate(35deg);
                                }
                            }

                            .statsBox {
                                display: flex;
                                justify-content: space-between;

                                @media screen and (max-width: 768px) {
                                    gap: 10px;
                                }
                                @media screen and (max-width: 575px) {
                                    flex-direction: column;
                                }

                                .block {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    gap: 5px;
                                    min-height: 60px;
                                    width: 24%;
                                    padding: 5px 10px;
                                    border: 1px solid var(--light-grey);
                                    border-radius: 10px;

                                    @media screen and (max-width: 768px) {
                                        width: fit-content;
                                    }
                                    @media screen and (max-width: 575px) {
                                        width: 200px;
                                    }

                                    .number {
                                        font-size: 20px;
                                        font-weight: 500;
                                        margin: 0;
                                        text-align: center;
                                    }

                                    .title {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        gap: 5px;
                                        font-size: 11px;
                                        margin: 0;

                                        button {
                                            padding: 0;
                                            cursor: default;

                                            svg {
                                                height: 14px;

                                                @media screen and (max-width: 768px) {
                                                    height: 20px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .post {
                            border-radius: 0;

                            @media screen and (max-width: 750px) {
                                min-height: 330px;
                            }

                            &:first-of-type {
                                padding-top: 10px;
                            }

                            .postBody {
                                img {
                                    width: 50%;

                                    @media screen and (max-width: 750px) {
                                        width: 100%;
                                    }
                                }

                                .postText {
                                    max-height: 80%;
                                    height: fit-content;
                                    width: 52%;

                                    @media screen and (max-width: 750px) {
                                        width: 100%;
                                    }

                                    h3 {
                                        margin-bottom: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            #newEmployeelist {
                display: flex;
                flex-direction: column;
                gap: 10px;

                h2 {
                    margin: 0;

                    .postCategory {
                        color: var(--orange);
                        border-color: var(--orange);
                    }
                }

                .list {
                    display: flex;
                    justify-content: flex-start;
                    gap: 1vw;
                    max-width: 100%;
                    padding-bottom: 12px;

                    .newEmployee {
                        position: relative;
                        height: 120px;
                        min-width: 215px;
                        max-width: 215px;
                        border-radius: 10px;
                        cursor: pointer;

                        @media screen and (max-height: 900px) {
                            height: 85px;
                            min-width: 175px;
                            max-width: 175px;
                        }
                        @media screen and (max-width: 1200px) {
                            height: 120px;
                            min-width: 215px;
                            max-width: 215px;
                        }

                        img {
                            height: 100%;
                            width: 100%;
                            border-radius: 10px;
                            object-fit: cover;
                        }

                        p {
                            position: absolute;
                            right: 5px;
                            bottom: 5px;
                            max-width: 88%;
                            margin: 0;
                            padding: 8px 10px;
                            font-size: 12px;
                            text-align: end;
                            text-wrap: balance;
                            border-radius: 10px;
                        }
                    }
                }

            }
        }
    }
}

.postModal {
    .modalHeader {
        width: 80%;
        margin: 3vh auto;

        @media screen and (max-width: 750px) {
            width: 95%;
        }

        .newEmployeeCategory {
            color: var(--orange);
            border-color: var(--orange);
        }

        .newsCategory {
            color: var(--green);
            border-color: var(--green);
        }

        // close
        button {
            right: 25px;
            top: 15px;
            height: 35px;
            width: 35px;
            margin-top: 0;
            margin-right: 0;

            svg {
                height: 100%;
                width: auto;
            }
        }
    }

    .modalContent {
        display: flex;
        flex-direction: column;
        gap: 3vh;
        align-items: center;
        margin-bottom: 5vh;

        .imageBox {
            position: relative;
            height: 340px;
            width: 80%;

            @media screen and (max-width: 750px) {
                width: 95%;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 10px;
            }

            .overlayText {
                position: absolute;
                bottom: 10px;
                right: 15px;
                font-size: 25px;
                font-weight: 300;
                color: #FFF;
                text-shadow: #000 0px 0px 5px;
            }

            .universLogo {
                position: absolute;
                top: 5px;
                right: 10px;
                height: 85px;
                width: auto;
            }
        }

        .highlighted {
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 100%;
            padding: 12px 0;
            background-color: var(--light-blue);

            h2,
            h3 {
                width: 80%;
                margin: 0 auto;

                @media screen and (max-width: 750px) {
                    width: 95%;
                }
            }

            h2 {
                display: flex;
                justify-content: space-between;
                gap: 4vw;
                font-size: 20px;
                text-transform: uppercase;

                span {
                    &:nth-of-type(2) {
                        font-size: 17px;
                        font-weight: 500;
                    }
                }
            }

            h3 {
                font-size: 16px;
                font-weight: 600;
                text-transform: capitalize;
            }
        }

        .description {
            max-height: 250px;
            width: 80%;
            margin: 0 auto;

            @media screen and (max-width: 750px) {
                width: 95%;
            }
        }
    }
}

#allPostsPage {
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        @media screen and (max-width: 900px) {
            flex-direction: column;
            align-items: center;
        }

        h1 {
            width: 90%;

            @media screen and (max-width: 900px) {
                font-size: 20px;
            }

            @media screen and (max-width: 500px) {
                font-size: 25px;
            }
        }

        >div {
            position: relative;
            display: flex;
            justify-content: space-between;
            gap: 5vw;
            width: 90%;

            @media screen and (max-width: 850px) {
                flex-direction: column;
            }

            @media screen and (max-width: 500px) {
                width: 100%;
            }

            #tableOfContents {
                max-height: 80vh;
                max-width: 35%;

                @media screen and (max-width: 1200px) {
                    max-width: 25%;
                }

                @media screen and (max-width: 900px) {
                    max-width: fit-content;
                }

                @media screen and (max-width: 850px) {
                    max-width: 100%;
                }

                @media screen and (max-width: 500px) {
                    margin-left: 10px;
                }

                &::-webkit-scrollbar-track {
                    background-color: var(--lighter-grey);
                }
                &::-webkit-scrollbar-thumb {
                    background-color: var(--light-grey);
                }
                

                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    margin: 10px 0 0 10px;
                    padding: 0;
                    list-style-type: none;

                    @media screen and (max-width: 900px) {
                        width: 100%;
                    }

                    li {
                        a {
                            color: #000;
                            text-decoration: none;
                            position: relative;

                            &::after {
                                content: " ";
                                position: absolute;
                                top: -6px;
                                left: -25px;
                                display: block;
                                height: 0px;
                                border-left: 3px solid var(--jra-blue);
                                transition: height .1s ease-in-out;
                            }

                            &.active::after {
                                height: 30px;
                            }
                        }
                    }
                }
            }

            .block {
                position: absolute;
                top: -50px;
                right: 0;
                display: flex;
                flex-direction: column;
                gap: 2vh;
                max-height: 87vh;
                width: 60%;

                @media screen and (max-width: 1200px) {
                    width: 65%;
                }

                @media screen and (max-width: 900px) {
                    position: initial;
                    width: 80%;
                }

                @media screen and (max-width: 850px) {
                    width: 100%;
                }

                .buttonBox {
                    display: flex;
                    justify-content: flex-end;
                    gap: 1vw;

                    @media screen and (max-width: 500px) {
                        .respoLaptop {
                            display: none;
                        }

                        .textButton {
                            min-height: 30px;
                            min-width: 30px;
                            padding: 5px !important;
                            justify-content: center;
                            border-radius: 30px;

                            svg {
                                height: 21px;
                                width: 21px;
                            }
                        }
                    }

                    .button {
                        line-height: 1.75;
                        letter-spacing: none;
                        text-transform: uppercase;
                    }
                }


                .autoScroll {
                    max-height: 90%;

                    .post {
                        @media screen and (max-width: 1000px) {
                            min-height: 330px;
                        }

                        h3 {
                            font-size: 15px;
                        }

                        .imageBox img,
                        .imageBox img.expanded,
                        .postText,
                        .postText.expanded {
                            transition: .3s;
                        }

                        .imageBox {
                            img {
                                height: 23vh;
                                width: 50%;

                                @media screen and (max-width: 1400px) {
                                    width: 100%;
                                }

                                &.expanded {
                                    height: fit-content;
                                    width: 100%;
                                }
                            }

                            .youtubeBox {
                                .youtubeLogo {
                                    left: 50% !important;
                                }
                            }
                        }

                        .postText {
                            width: 53%;

                            @media screen and (max-width: 1000px) {
                                width: 100%;
                            }

                            h4,
                            p {
                                font-size: 13px;
                            }

                            h4 {
                                font-weight: 700;
                            }

                            &.expanded {
                                position: inherit;
                                display: flex;
                                flex-direction: column;
                                gap: 1vh;
                                min-width: 100%;
                                margin: 0;

                                h4 {
                                    margin: 5px 0 -7px;
                                }

                                h4,
                                p {
                                    font-size: 15px;
                                }
                            }

                            .buttonBox {
                                display: flex;
                                justify-content: end !important;
                                gap: 1vw;

                                .button {
                                    &.outsideLink {
                                        padding: 4px 10px 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    // responsive text

    h2 {
        @media screen and (max-width: 1280px) {
            font-size: 12px !important;
        }

        @media screen and (max-width: 500px) {
            font-size: 18px !important;
        }
    }

    h3 {
        @media screen and (max-width: 1280px) {
            font-size: 13px !important;
        }

        @media screen and (max-width: 500px) {
            font-size: 16px !important;
        }
    }

    h4,
    p,
    li {
        @media screen and (max-width: 1280px) {
            font-size: 10px !important;
        }

        @media screen and (max-width: 500px) {
            font-size: 13px !important;
        }
    }

    .button {
        @media screen and (max-width: 1280px) {
            font-size: 10px !important;
        }

        @media screen and (max-width: 500px) {
            font-size: 16px !important;
        }
    }

    .statsBox .number {
        @media screen and (max-width: 1280px) {
            font-size: 18px !important;
        }
    }
}