header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  height: 55px;
  max-width: 100%;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 7px 0 #3b3b3b70;
  z-index: 3;

  @media screen and (max-width: 1200px) {
    height: 55px;
  }
  @media screen and (max-width: 650px) {
    height: 60px;
  }

  > div {
    display: flex;
    gap: 2vw;
    align-items: center;

    @media screen and (max-width: 650px) {
      gap: 4vw;
    }
  }

  nav {
    position: relative;
    display: inline-block;
    justify-content: center;
    width: 50px;
    box-sizing: border-box;
  }

  img {
    height: 47px;
    align-items: center;

    @media screen and (max-width: 500px) {
      height: 47px;
      width: 55px;
      object-fit: cover;
      object-position: 0;
    }

    @media screen and (max-width: 393px) {
      height: 40px;
      width: 48px;
    }
  }

  h1 {
    text-align: center;
    font-size: 24px;

    @media screen and (max-width: 700px) {
      font-size: 20px;
    }

    @media screen and (max-width: 617px) {
      display: none;
    }
  }
  .user {
    justify-content: end;
  }
}

nav {
  list-style-type: none;
  align-items: center;
  display: flex;
  position: fixed;
  justify-content: space-between;
  z-index: 3;
  width: 100%;
}
