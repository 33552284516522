  .StyledModal {
  	position: fixed;
  	z-index: 1300;
  	inset: 0;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  }

  .Backdrop {
  	z-index: -1;
  	position: fixed;
  	inset: 0;
  	background-color: rgb(255, 255, 255);
  	-webkit-tap-highlight-color: transparent;
  }

  .MuiBackdrop-open {
  	background-color: rgb(255, 255, 255);
  	-webkit-tap-highlight-color: transparent;
  }


  .box {
  	margin: 0;
  	padding-block: 0rem;
  	position: absolute;
  	top: 50%;
  	left: 50%;
  	transform: translate(-50%, -50%);
  	max-height: 100vh;
  	background-color: rgb(255, 255, 255);
  	border: none;
  	border-radius: 10px;
  	box-shadow: 0 8px 10px 1000px rgb(0 0 0 / 16%), 0 16px 24px 10px rgb(0 0 0 / 11%), 0 6px 30px 20px rgb(0 0 0 / 10%);
  	min-width: 650px;

  	.respscroll {
  		padding: 0rem;
  		border: none;
  		overflow-y: auto;
  		max-width: 100%;
  		max-height: 80vh;
  		margin-bottom: 0rem;
  		display: flex;
  		flex-direction: row;
  		justify-content: center;
  		margin-bottom: 20px;
  		font-size: 13px;
  		flex-wrap: wrap;

  		.items_large {
  			width: 100%;
  			text-align: center;
  		}

  		.items {
  			display: flex;
  			flex-direction: column;
  			justify-content: space-between;
  			gap: 20px;
  			width: 50%;
  			text-align: center;
  			align-items: center;
			margin: 2vh 0;

  			button {
  				max-width: 50%;
  			}
  		}
  	}

  	.checkContenair {
  		display: flex;
  		flex-direction: row;
  		justify-content: space-between;
  		width: 100%;
  		gap: 2em;

  		.uiform {
  			width: calc(50% - 4rem);
  		}
  	}

  	@media screen and (max-width:678px) {
  		.checkContenair {
  			flex-direction: column;

  			.uiform {
  				width: calc(100% - 4rem);

  			}
  		}
  	}

  	.buttonForm {
  		display: flex;
  		align-items: center;
  		justify-content: center;
  		border: none;
  		height: 5rem;
  		padding-bottom: 1.5rem;
  		gap: 2rem;

  	}

  }

  .title {
  	text-align: center;
  	font-size: 25px;
	margin: 10px 0;
  }

  .line {
  	border-bottom: 1px solid black;
  	width: 100%;
  }