
#myJraLoader {
    background-color: #ffffffd1;
    z-index: 3;
    
    &.formLoader {
        position: absolute;
        width: 100%;
        margin: 15% 0 0 -2%;
    }

    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 10em;
        width: 11em;
        transform: translate(-50%, -50%);

        .image {
            position: relative;
    
            img {
                position: absolute;
                height: 10em;
                animation: pulse 1s infinite;
            }
        }
    
        @keyframes pulse {
            0% {
                transform: scale(1);
            }
            70% {
                transform: scale(1.2);
            }
            100% {
                transform: scale(1);
            }
        }
    }
}