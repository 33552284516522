
.popperRoot {
  max-width: 275px;
  z-index: 3;
}


.dropdownMenu {
	position: sticky;
	display: flex;
	flex-direction: row;
	justify-content: center;
	
	hr {
		margin-inline-start: 1rem;
		height: 45px;
		align-self: center;
	}
}
.menu {
	font-size: 15px;

	.username {
		display: flex;
		gap: 6px;
		flex-wrap: wrap;
		padding: 1vh 1vw;

		p {
			margin: 0;
			overflow-wrap: anywhere;
		}
	}

	.job {
		margin: 0;
		padding: 1vh 1vw;
		text-wrap: pretty;
	}

	hr {
		margin: 1vh 0;
	}
		
	.menuItem {
		padding: 1vh 1vw;
		display: flex;
		align-items: center;
		justify-content: start;
		cursor: pointer;
		transition: color 0.3s;

		
		.menuLink {
			display: flex;
			align-items: center;
			color: #6d6d6d;
			text-decoration: none;
		}
	
		.MuiListItemIcon-root {
			min-width: auto;
		}

		svg {
			fill: #6d6d6d;
			align-items: center;
		}
		
		.menuLink {
			color: #6d6d6d;
			text-decoration: none;
		}
	
		&.active {
			svg {
				fill: #008EC8;
			}
			.menuLink {
				color: #008EC8;
				font-weight: 700 !important;
			}
		}

		&:hover{
			svg {
				fill: #008EC8;
			}
			.menuLink {
				color: #008EC8;
				font-weight: 700 !important;
			}
		}
	}
}

    
.lienUtile {
	margin-top: 0.5rem;

	@media screen and (max-width: 650px) {
		margin-top: 4px;
	}

	svg {
		@media screen and (max-width: 650px) {
			width: 35px;
			height: 35px;
		}
	}
}

