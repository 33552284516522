// @font-face {
//   font-family: 'Montserrat';
//   src: local('Montserrat'), url(./assets/Montserrat/Montserrat-Regular.ttf) format('woff');
// }

body {
  margin: 0;
  padding: 0rem;
  margin: 0;
  font-family: "Montserrat", "Arial", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

// https://coolors.co/008ec8-74c61d-d32a2d-f78234-ffc800-8b8b8b-c2c2c2
:root {
  --jra-blue: #008EC8;
  --jra-blue-pale: #399EC6;
  --jra-blue-dark: #006e99;
  --light-blue: #CCE9F0;
  --green: #74C61D;
  --red: #D32A2D;
  --red-disabled: #f1433e;
  --orange: #F78234;
  --yellow: #FFC800;
  --grey: #8B8B8B;
  --light-grey: #C2C2C2;
  --lighter-grey: #EEE;
}


.hidden {
  display: none !important;
}

.disabledText {
  padding: 10px 20px;
  color: var(--light-grey);
  font-size: 13px;
  font-weight: 300;
}

// Start main content container
.background {
  margin: 0;
  padding: 75px 2vw 2vh;
  min-height: 100vh;
  background-color: #F7F7F7;

  h1 {
    margin: 0 0 2vh;
    font-size: 25px;
    font-weight: 600;

    @media screen and (max-width: 1500px) {
      font-size: 24px;
    }
  }

  .backsquare {
    max-width: 100%;
    padding: 3vh 2vw;
    overflow: hidden;
    background-color: #FFFFFF;
    border-radius: 20px;

    @media screen and (max-width: 550px) {
      padding: 10px 5vw;
    }

    .tableContainer {
      margin-bottom: 1rem;

      @media screen and (max-width: 600px) {
        height: auto;
      }
    }

    .title2 {
      float: left;
    }
  }
}

// buttons

.buttonBox {
  display: flex;
  justify-content: space-between;
  gap: 1vw;
}

.button {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  height: fit-content;
  min-width: 100px;
  width: fit-content;
  margin: 0 !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #fff;
  background-color: var(--jra-blue);
  line-height: 1.75;
  letter-spacing: normal;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 1px 1px 1px #00000052 !important;
  transition: .2s;

  @media screen and (max-width: 1280px) {
    font-size: 10px !important;
  }

  @media screen and (max-width: 500px) {
      font-size: 16px !important;
  }

  &:hover {
    background-color: var(--jra-blue-dark);
    box-shadow: none;
    cursor: pointer;
    transition: .2s;
  }

  svg {
    height: 15px;
    width: 15px;
  }

  &.smallButton {
    font-size: 11px !important;
    padding: 4px 9px !important;
  }
}

.roundButton {
  min-height: 30px;
  min-width: 30px;
  padding: 5px !important;
  justify-content: center;
  border-radius: 30px;

  svg {
    height: 21px;
    width: 21px;
  }
}


// 

.sticky {
  position: sticky;
  top: 0;
  padding-bottom: 1vh;
  background-color: #FFF;
  z-index: 1;
}

.hoverShadow {
  border: 2px solid var(--lighter-grey);
  box-shadow: 1px 1px 1px #00000052 !important;
  transition: .2s;

  &:hover {
    transform: scale(.97);
    box-shadow: none;
    transition: .2s ease-out;
    cursor: pointer;
  }
}

.close {
  cursor: pointer;
  background: none;
  border: none;
  position: absolute;
  right: 0;
  margin-right: 10px;
  border-radius: 10px;

  svg {

    path:hover,
    path:focus {
      border-color: rgba(0, 0, 0, 0.55);
      box-shadow: rgba(0, 0, 5, 0.1) 0 6px 14px;
      color: rgba(0, 0, 0, 0.65);
    }

    path:hover {
      transform: translateY(-2px);
    }

    path:active {
      background-color: #F0F0F1;
      border-color: rgba(0, 0, 0, 0.55);
      box-shadow: rgba(0, 0, 5, 0.06) 0 3px 5px;
      color: rgba(0, 0, 0, 0.65);
      transform: translateY(0);
    }
  }
}


//rewrite datagrid css
* {
  box-sizing: border-box;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    background-color: #F5F5F5;
    box-shadow: none;
  }

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background-color: #F5F5F5;
    box-shadow: none;

  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, .3);
    background-color: #99999973;
    box-shadow: none;

  }
}

.MuiDataGrid-virtualScroller {
  padding-bottom: 5px !important;
  overflow-y: auto !important;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--lighter-grey);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--jra-blue-pale);
    border-radius: 10px;
  }
}


// auto scroll

.autoScrollVertical {
  padding-right: 5px !important;
  overflow-y: auto !important;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--lighter-grey);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--jra-blue-pale);
    border-radius: 10px;
  }
}

.autoScrollHorizontal {
  padding-bottom: 5px !important;
  overflow-x: auto !important;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--lighter-grey);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--jra-blue-pale);
    border-radius: 10px;
  }
}


//Progress in datatable
.progress {
  position: relative;
  display: inline-flex;

  .progressItem {
    max-width: 30px;
    max-height: 30px;
    margin: auto;
  }

  .progressContainer {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }
}

// 

.spinner {
  border: 4px solid var(--light-grey);
  border-top: 4px solid var(--jra-blue);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}



// 
.MuiSelect-select {
  p {
    margin: 0;

    span {
      display: none;
    }
  }

  img {
    display: none;
  }
}

@media screen and (max-width: 870px) {
  .MuiDataGrid-virtualScrollerContent {
    width: fit-content !important;
  }

  .css-yrdy0g-MuiDataGrid-columnHeaderRow>div:nth-last-of-type(3),
  .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row>div:nth-last-of-type(3) {
    display: none;
    min-width: 0 !important;
    padding: 0 !important;
  }
}

@media screen and (max-width: 920px) {
  .MuiDataGrid-virtualScrollerContent {
    width: fit-content !important;
  }

  .css-yrdy0g-MuiDataGrid-columnHeaderRow>div:nth-last-of-type(2),
  .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row>div:nth-last-of-type(2) {
    display: none;
    min-width: 0 !important;
    padding: 0 !important;
  }
}

@media screen and (max-width: 740px) {
  .MuiDataGrid-virtualScrollerContent {
    width: fit-content !important;
  }

  .css-yrdy0g-MuiDataGrid-columnHeaderRow>div:nth-last-of-type(4),
  .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row>div:nth-last-of-type(4) {
    display: none;
    min-width: 0 !important;
    padding: 0 !important;
  }
}


.MuiDataGrid-row {
  cursor: pointer;
  // width: 100%;

  .MuiDataGrid-cellContent {
    cursor: pointer;
  }
}

.css-j204z7-MuiFormControlLabel-root {
  .css-gr29dd-MuiFormControlLabel-asterisk {
    display: none;
  }
}


// DataGrid
@media screen and (max-width: 870px) {
  .MuiDataGrid-virtualScrollerContent {
    width: fit-content !important;
  }

  .css-yrdy0g-MuiDataGrid-columnHeaderRow>div:nth-last-of-type(3),
  .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row>div:nth-last-of-type(3) {
    display: none;
    min-width: 0 !important;
    padding: 0 !important;
  }
}

@media screen and (max-width: 920px) {
  .MuiDataGrid-virtualScrollerContent {
    width: fit-content !important;
  }

  .css-yrdy0g-MuiDataGrid-columnHeaderRow>div:nth-last-of-type(2),
  .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row>div:nth-last-of-type(2) {
    display: none;
    min-width: 0 !important;
    padding: 0 !important;
  }
}

@media screen and (max-width: 740px) {
  .MuiDataGrid-virtualScrollerContent {
    width: fit-content !important;
  }

  .css-yrdy0g-MuiDataGrid-columnHeaderRow>div:nth-last-of-type(4),
  .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row>div:nth-last-of-type(4) {
    display: none;
    min-width: 0 !important;
    padding: 0 !important;
  }
}


.MuiDataGrid-row {
  cursor: pointer;
  // width: 100%;

  .MuiDataGrid-cellContent {
    cursor: pointer;
  }
}

// !important ne pas le déplacer!
.css-j204z7-MuiFormControlLabel-root {
  .css-gr29dd-MuiFormControlLabel-asterisk {
    display: none !important;
  }
}


.css-331n1i-MuiInputBase-root-MuiOutlinedInput-root {
  .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
    box-sizing: content-box;
  }
}

.css-162u2uv-MuiInputBase-root-MuiOutlinedInput-root {
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    box-sizing: content-box;
  }
}

.css-1jjqkmm-MuiInputBase-root-MuiOutlinedInput-root {
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    box-sizing: content-box;
  }
}

.css-1nrlq1o-MuiFormControl-root {
  .css-vwk730-MuiFormLabel-root-MuiInputLabel-root {
    padding-block: 6px;
  }
}

.lineContain {
  padding-bottom: 1rem;
  padding-top: 0;
  display: flex;
  flex-direction: row;
  border: none;
  max-width: 100%;
  height: auto;
  justify-content: end;

  @media screen and (max-width: 634px) {
    padding-top: 4rem;
  }
}


.tableContainer {
  .addbutton {
    margin-block: 0;
  }
}

@media screen and (max-width:617px) {
  .tableContainer {
    text-align: center;
  }

  .lineContain {
    flex-direction: column;

    .addbutton {
      width: 100%;
    }
  }

  .editdelete {
    position: absolute;
    bottom: 22px;
    right: 15px;
  }
}

.css-xofe3k-MuiTypography-root {
  line-break: anywhere;
}

.css-15k13mk-MuiFormLabel-root-MuiInputLabel-root {
  z-index: 0 !important;
}