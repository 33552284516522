.content {
    display: flex;
    justify-content: space-between;
    height: 100vh;
    background-color: #f9f9fc;

    @media screen and (max-width: 950px) {
        flex-direction: column;
    }

    img.bgMyJra {
        height: 100%;
        width: 100%;
        object-fit: cover;

        @media screen and (max-width: 950px) {
            height: 20vh;
        }
    }

    .login {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
        min-width: 60%;

        @media screen and (max-width: 950px) {
            top: 20vh;
            max-height: 50vh;
            min-width: 100%;
        }

        .formBox {
            display: flex;
            align-self: center;
            flex-direction: column;
            align-items: center;
            padding-inline: 26px;

            @media screen and (max-width: 950px) {
                min-height: 80vh;
                padding-inline: 1rem;
            }
    
            h2 {
                color: #272727c5;
                font-weight: 300;
                font-size: 1.2em;
            }

            .header {
                color: #161616c0;
                letter-spacing: 1.5;
                text-align: center;
    
                h2 {
                    margin: 20px 0;
                }
    
                a {
                    padding: 10px 30px;
                    text-decoration: underline;
                    margin-left: 2rem;
                }
            }
    
            form {
                display: flex;
                align-self: center;
                flex-direction: column;
                gap: 1rem;
                align-items: center;
                margin: 20px 0;
                width: 100%;
    
                .googleConnectButton {
                    >a {
                        label {
                            box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                            background-color: #fff;
                            border-radius: 10px;
                        }
                    }
                }
    
                .accordion {
                    width: 75%;
                    border-radius: 10px;
    
                    &::before {
                        display: none;
                    }
    
                    > div {
                        width: fit-content;

                        &:first-of-type {
                            gap: 10px;
                        }
                    }
                }
            }
    
            a:link {
                color: #000000;
                font-weight: 500;
                font-size: 14px;
            }

            a:visited {
                color: #000000;
            }
            
        }
    }
}