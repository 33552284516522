//TEST MODAL
.container{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 70px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  &.containerFull{
      justify-content: space-around;
      height: 70px;
      width: 100%;
  }
  &.containerLeft{
      justify-content: center;
      left: 0;
      width: 50%;
  }
}

.buttonNS {
  width: 130px; 
}