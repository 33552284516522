.uicheckbox {
    border: 2px solid #e1e4e8;
    border-radius: 8px;
    max-width: 100%;
    padding: 10px 1rem;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    max-height: 55vh;

    .requiredCustom input[required]::-webkit-validation-bubble-text,
    .requiredCustom input[required]::-moz-validation-bubble-text,
    .requiredCustom input[required]::-ms-validation-bubble-text,
    .requiredCustom input[required]::validation-bubble-text {
        display: none;
    } 

    .uicheckbox {
      padding: 8.5px 14px;
      @media screen and (max-width: 650px) {
        width: 100%;
      }
  }
  }

  .soloCheckbox {
    background-color: none;
    border: none;
    border-radius: 0px;
    max-width: 100%;
    height: 22rem;
    padding: 10px 1rem;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: thin;
    scrollbar-color: #c7c7c773 #F5F5F5;

  }

  .containercheck{ // ceci n'est pas un groupe de container mais un seule container un gap n'est pas utile
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .errorMessage{
    color: #d63a5a;
    font-size: 15px;
    margin-top: 5px;
  }


