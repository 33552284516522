#accueilConfigPage {
    .buttonBox {
        margin-bottom: 2vh;

        @media screen and (max-width: 600px) {
            flex-direction: column;

            >div {
                flex-direction: column;
            }
        }
    }

    .tableContainer {
        height: 70vh;

        // table 
        .MuiDataGrid-cell {
            padding: 2px 10px;
        }

        .photoPostCell {
            height: 100%;
            width: 100%;
            background-size: 100%;
            background-position: center;
        }
    }
}


// modal

form {

    .row {
        display: flex;
        flex-direction: row;
        gap: 30px;

        @media screen and (max-width: 850px) {
            flex-direction: column;
        }
    }

    .column {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0;

        @media screen and (max-width: 800px) {
            gap: 2vh;
        }
    }

    .content {
        padding: 4vh 0;
        background-color: #FFF;

        .column.firstBox,
        .column.secondBox {
            height: inherit;
            width: 50%;

            @media screen and (max-width: 850px) {
                width: 100%;
            }
        }

        .input>* {
            @media screen and (max-width: 800px) {
                font-size: 20px !important;
            }
        }
    }


    &.postForm {
        .content {
            padding: 4vh 2vw 60px;

            @media screen and (max-width: 769px) {
                align-items: center;
            }

            >.row {
                @media screen and (max-width: 769px) {
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                }
            }

            .selectsBox {
                display: flex;
                gap: 2vh;

                .MuiPaper-elevation {
                    img {
                        height: 20px;
                    }
                }
            }

            .photoPostBox {
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 2vh;

                .buttonBox {
                    display: flex;
                    gap: 1vw;

                    .buttonCompressImg {
                        display: flex;
                        gap: 8px;
                        width: fit-content;
                        font-size: 10px;

                        @media screen and (max-width: 800px) {
                            font-size: 15px;
                        }

                        svg {
                            height: 15px;
                            width: 15px;

                            @media screen and (max-width: 800px) {
                                height: 20px;
                                width: 20px;
                            }
                        }
                    }

                    .questionMark {
                        height: 28px;
                        width: 28px;
                        padding: 5px;
                        fill: var(--grey);
                        border: 1px solid var(--grey);
                        border-radius: 50%;
                        cursor: pointer;
                    }
                }


                .photoPost {
                    position: relative;
                    height: 204px;
                    width: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    cursor: pointer;
                    border-radius: 10px;
                    opacity: .8;

                    @media screen and (max-width: 769px) {
                        height: 150px;
                        background-color: #f9f9f9;
                        background-size: inherit;
                    }

                    &:hover {
                        opacity: 1;
                    }

                    svg {
                        fill: #ABABAB;
                        position: absolute;
                        top: -10px;
                        right: -10px;
                        height: 40px;
                        width: 40px;
                        background-color: white;
                        padding: 8px;
                        border-radius: 50%;
                        box-shadow: 1px 1px 1px #ABABAB;
                        cursor: pointer;
                    }

                    &.active {
                        background-clip: content-box;
                        border: 3px solid #fff;
                    }

                    &.errorInput {
                        border: 1px solid var(--red);
                    }
                }

                input[type="file"] {
                    display: none;
                }
            }

            .autocomplete {
                width: 100% !important;
            }

            p,
            .errorMessage {
                margin: 0;
                font-size: 12px;
                color: var(--red);
                text-align: center;
                text-transform: uppercase;
            }

            .errorMessage {
                margin-top: -15px;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                font-weight: 400;
            }
        }

        .groupContenair {
            display: flex;
            flex-direction: row;
            gap: 1vw;
            justify-content: space-evenly;
            height: inherit;
            width: 100%;
            margin: 4vh 0;

            @media screen and (max-width: 800px) {
                flex-direction: column;
                align-items: center;
            }
        }

        &#postStatsForm {

            .statsContent {

                .header {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;
                    margin: 2vh 0 0;
    
                    h3 {
                        text-align: center;
                        font-size: 15px;
                        font-weight: 400;
                    }
    
                }
    
                >.column {
                    gap: 0;
                    padding: 20px 2vw;
    
                    >.row {
                        gap: 0;
    
                        &:first-of-type {
                            .postStat {
                                padding: 25px;
                                border-bottom: 1px solid var(--light-grey);
    
                                &:first-of-type {
                                    border-right: 1px solid var(--light-grey);
    
                                    @media screen and (max-width: 850px) {
                                        border-right: none;
                                    }
                                }
                            }
                        }
    
                        &:nth-of-type(2) {
                            .postStat {
                                &:first-of-type {
                                    border-right: 1px solid var(--light-grey);
    
                                    @media screen and (max-width: 850px) {
                                        border-right: none;
                                        border-bottom: 1px solid var(--light-grey);
                                    }
                                }
                            }
                        }
    
                        >.postStat {
                            width: 100%;
                            padding: 25px;
                        }
                    }
                }
            }
        }
    }
}