  .StyledModal {
    position: fixed;
    z-index: 1300;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Backdrop {
    z-index: -1;
    position: fixed;
    inset: 0;
    background-color: #00000045;
    -webkit-tap-highlight-color: transparent;
  }

  .MuiBackdrop-open {
    background-color: #00000045;
    -webkit-tap-highlight-color: transparent;
  }
  

  .box {
    min-height: 440px;
    max-width: 900px;
    max-height: 90vh;
    overflow: hidden;
    overflow-y: auto;
    width: 90vw;
    margin: 0;
    padding-block: 0rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(255, 255, 255);
    border: none;
    border-radius: 10px;    
    box-shadow: 0px 0px 20px 0px #00000038;

    @media screen and (max-width:666px){
        width: 70vw;
    }
    @media screen and (max-width:882px) {
        width: 95vw;
    }
     @media screen and (max-width:666px) {
        height: 95%;
    }

    &.isSubmitting {
      pointer-events: none;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: 100%;
        background-color: #ffffff;
        border-radius: 15px;
        z-index: 1;
      }

      .respscroll {
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50px;
          height: 50px;
          border: 4px solid rgba(0, 0, 0, 0.1);
          border-top-color: #000;
          border-radius: 50%;
          animation: spin 1s linear infinite;
          z-index: 2;
        }
      }

      .fullSizeBox {
        display: none;
      }
    }
    
    @keyframes spin {
      to {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }

    .modalHeader {
      border-bottom: 1px solid black;

      h2 {
        width: 80%;
        margin: 2vh auto;
      }
    }

  
    .respscroll{
      padding-inline: 2%;
    }

    .checkContenair {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      gap: 2em;

      .uiform {
        width: 50%;
      }
    }

    @media screen and (max-width:678px) {
      .checkContenair{
        flex-direction: column;
        .uiform {
          width: calc(100% - 4rem);
          
        }
      }
    }

    .fullSizeBox {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      height: 70px;
      width: 100%;
    }

    .bigPopup {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 750px;
      width: 100%;

      img {

      }
    }
  }

.title {
  text-align: center;
  top: 10px;
  width: 55%;
  text-transform: uppercase;
  font-size: 23px;

  @media screen and (max-width: 530px){
    font-size: 17px;
  }
}

.subtitle {
  text-align: center;
  margin: 0px auto;
  @media screen and (max-width: 530px){
    font-size: 15px;
  }
}

.close{
  cursor: pointer;
  background: none;
  border: none;
  position: absolute !important;
  right: 15px;
  top: 15px;
  margin-right: 10px;
  border-radius: 10px;
} 

.buttonNS {
  width: 130px; 

  &.buttonDraft {
    background-color: var(--orange);
  }

  &.buttonPublish {
    background-color: var(--green
    );
  }
}

.postConfigModal {
  .fullSizeBox {
    justify-content: center;
    gap: 2vw;
  }
}