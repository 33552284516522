.lineContain {
  padding-bottom: 1rem;
  padding-top: 4rem;
  display: flex;
  flex-direction: row;
  border: none;
  max-width: 100%;
  height: auto;
  justify-content: end;

}

.tableContainer {

  .addbutton {
    margin-bottom: 20px;
  }
}

@media screen and (max-width:681px) {
  .tableContainer {
    text-align: center;
  }

  .lineContain {
    flex-direction: column;

    .addbutton {
      width: 100%;
      margin-block-start: 1.5rem;
    }
  }

  .editdelete {
    position: absolute;
    bottom: 22px;
    right: 15px;
  }
}

.uiform {
  padding: 1rem 0rem 4.5rem 0rem;
}


.popform {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 1.5rem;
  padding-inline: 1.5rem;

  .popPictureContent {
    flex: 50%;
  }

  .imageUpload {
    margin: 8px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .rectangleContainer {
    margin-top: 16px;
    margin-bottom: 17px;
    margin-inline: auto;
    width: auto;
    height: 261px;
    padding: 0.5rem;
    border-radius: 10px;
    border: #020202;
    box-shadow: 0 2px 5px rgba(151, 151, 151, 0.555);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    &:hover {
      opacity: 0.8;
    }

    svg {
      border: 10px solid #000000;
      box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.274), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      width: 2.2rem;
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
      background-color: white;
      border: #181a1d;
      padding: 4px;
      border-radius: 50%;
    }

    &.active {
      background-clip: content-box;
    }

    .selectedImage {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      border-radius: 10px;

    }

  }

  .popcontainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-block-end: 1rem;
    gap: 1rem;

    .popContent {
      flex: 50%;

      .textField {
        margin-top: 8px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.3rem;
      }

      @media screen and (max-width: 516px) {
        .textField {
          flex-direction: row;
        }
      }

    }
  }

  @media screen and (min-width: 1000px) {
    .popcontainer {
      flex-direction: column;
      gap: 0.2rem;
    }
  }

  @media screen and (max-width: 560px) {
    .popcontainer {
      flex-direction: column;
      gap: 0.2rem;
    }
  }

  @media screen and (max-width: 1000px) {
    .rectangleContainer {
      margin-top: 10px;
      margin-bottom: 0px;
      height: 250px;
    }
  }

  @media screen and (max-width: 560px) {
    .rectangleContainer {
      margin-top: 18px;
      margin-bottom: 0px;
      height: 200px;
    }
  }

}

@media screen and (max-width: 1000px) {
  .popform {
    flex-direction: column;
    gap: 0.5rem;
  }
}

.createur {
  padding: 1.5rem;

}


.checkContenair {
  display: flex;
  flex-direction: row;
  border: none;
  max-width: 100%;
  height: auto;
  gap: 0.5rem;
  justify-content: space-evenly;
}

@media screen and (max-width: 1315px) {
  .checkContenair {
    justify-content: space-around;
  }
}

@media screen and (max-width:666px) {
  .checkContenair {
    flex-direction: column;
    gap: 15px;
  }
}

@media screen and (max-width:370px) {
  .checkContenair {
    padding: 1em 10px;
    gap: 10px;
  }
}

// popup //
.boxPopup {
  position: absolute;
  padding: 0 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: auto;
  height: auto;
  border: none !important;
  box-shadow: rgb(0, 0, 0) 5px 5px 25px;
}

.boxPopup:focus,
.boxPopup *:focus {
  outline: none;
}