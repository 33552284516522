.content{
  display: flex;
  justify-content: space-between;
  background-color: #f9f9fc;
  >div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    max-width: 500px;
    margin: auto;
  }
}

.logo_container{
  text-align: center;
}

.container_form{
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border: solid 1px #dde1e3;
  border-radius: 10px;
  form{
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 20px 0;
    width: 100%;
    a{
      text-decoration: underline;
    }
    a:link {
      color: #000000;
      font-weight: 500;
      font-size: 14px;
  }
  a:visited {
      color: #000000;
  }
    label{
      background-color: #f9f9fc;
    }
  }
}

.heading{
  color:#88889b;
  letter-spacing: 1.5;
  text-align: center;
  h2{
    color:#cacad0;
    font-weight: 100;
    font-size: 1.2em;
    margin: 20px 0;
  }
}
.hidden{
  display: none!important;
}

.info{
  color: var(--mainBlue);
  text-align: center;
}

.error{
  color:red;
  text-align: center;
}