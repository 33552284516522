* {
  padding: 0;
  margin: 0;
}

.header {
  height: 45px;
  line-height: 45px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  display: block;
  margin: 0;
  width: 100%;
  min-width: 1024px;
  height: 48px;
  max-height: 44px;
  background: rgba(221, 221, 221, 0.92);
  font-size: 17px;
  user-select: none;
  padding-left: 60px;
}

.org_tree {
  ul {
    padding-top: 20px;
    position: relative;
    transition: all 0.5s;

    > li {
      place-items: center;
    }
  }

  li {
    float: left;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0 5px;
    transition: all 0.5s;
    margin-bottom: 50px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 50%;
      border-top: 1px solid #ccc;
      width: 50%;
      height: 20px;
    }

    &::after {
      right: auto;
      left: 50%;
      border-left: 1px solid #ccc;
    }

    &:only-child {
      &::before,
      &::after {
        display: none;
      }

      padding-top: 0;
      float: initial;
    }

    &:first-child::before,
    &:last-child::after {
      border: none;
    }

    &:last-child::before {
      border-right: 1px solid #ccc;
      border-radius: 0 5px 0 0;
    }

    &:first-child::after {
      border-radius: 5px 0 0 0;
    }
  }

  ul ul::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #ccc;
    width: 0;
    height: 20px;
  }

  li a {
    border: 1px solid #ccc;
    padding: 5px 10px;
    text-decoration: none;
    color: #666;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    display: inline-block;
    border-radius: 5px;
    transition: all 0.5s;
  }

  li a:hover,
  li a:hover + ul li a {
    background: #c8e4f8;
    color: #000;
    border: 1px solid #94a0b4;
  }

  li a:hover + ul li::after,
  li a:hover + ul li::before,
  li a:hover + ul::before,
  li a:hover + ul ul::before {
    border-color: #94a0b4;
  }
}

.card {
  display: flex;
  gap: 10px;
  height: 85px;
  max-width: 400px;
  width: fit-content;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  img {
    height: 85px;
    width: 85px;
    border-radius: 50%;
  }

  .card_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;

    h2 {
      font-weight: 600;
      line-height: 20px;
    }

    p {
      line-height: 20px;
      font-size: small;
    }
  }

  .card_footer {
    display: flex;
    align-items: center;
    padding: 3px;
    
    svg {
      height: 30px;
      width: auto;
      opacity: .7;
      fill: var(--jra-blue);
      
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
}
