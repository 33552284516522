.lineContain {
  padding-block: 1rem;
  display: flex;
  border: none;
  max-width: 100%;
  height: auto;
  justify-content: end;

}

.tableContainer {

  // defaut responsive colonnes
  .MuiDataGrid-root {

    .actionsColumnHeader,
    .actionsColumnCell {
      display: flex !important;
    }
    .actionsColumnHeader {
      svg {
        display: none;
      }
    }
    .actionsColumnCell {
      svg {
        fill: var(--jra-blue);
      }
    }

    .MuiChip-root {
      border: none;
    }

    .MuiDataGrid-cell:focus-within {
      outline: none !important;
    }
  }

  @media screen and (max-width: 1600px) {
    .MuiDataGrid-virtualScrollerContent {
      width: fit-content !important;
    }

    .css-yrdy0g-MuiDataGrid-columnHeaderRow > div:nth-last-of-type(-n + 3),
    .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row > div:nth-last-of-type(-n + 3) {
      display: none;
    }
  }

  .MuiDataGrid-cell {
    span {
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        margin: 4px 0 0 0;
      }
    }
  }

  .addbutton {
    margin-bottom: 20px;
    margin-left: 20px;

  }
}

@media screen and (max-width:681px) {
  .tableContainer {
    text-align: center;
  }

  .lineContain {
    flex-direction: column;

    .addbutton {
      width: 100%;
      margin-block-start: 1.5rem;
    }
  }

  .editdelete {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .photoPost {
    height: 100px;


    span {
      height: 100%;
      width: 100%;
      background-size: 100%;
      background-position: center;
    }
  }

}

.card-item span {
  word-wrap: break-word;
  white-space: pre-wrap;
  max-width: 330px;
}


.css-1oj2twp-MuiPagination-root {
  position: relative !important;
  justify-content: center !important;
  margin-block: 1rem !important;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  flex-wrap: wrap !important;
  justify-content: space-around !important;
  padding: 0 !important;
}