
.profileBackground {
    display: flex;
    justify-content: center;
    padding: 80px 2vw 3vh;
    background-image: url('../img/profile-background.png');
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: 1250px) {
        height: inherit;
    }



    #profile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 2vw;
        align-items: center;
        width: 75%;

        @media screen and (max-width: 1850px) {
            width: 90%;
        }
        @media screen and (max-width: 1400px) {
            width: 95%;
        }
        @media screen and (max-width: 1250px) {
            flex-direction: column;
        }
        @media screen and (max-width: 550px) {
            width: 100%;
        }

        h2 {
            margin: 0 0 1vh 0;
        }

        .box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 3vh 2vw;
            background-color: #FFF;
            border-radius: 15px;

            @media screen and (max-width: 1250px) {
                flex-direction: column;
                padding: 4vh 4vw !important;
            }
            @media screen and (max-width: 550px) {
                padding: 4vh 5vw !important;
            }
        }

        .buttonProfile {
            display: flex;
            justify-content: flex-end;
        }
        
        #editProfileInfos {
            height: 90%;
            width: 35%;
            padding: 5vh 2vw;

            @media screen and (max-width: 1250px) {
                width: 95%;
            }

            h2 {
                margin: 0 0 4vh 0;
            }
            
            .content {
                display: flex;
                flex-direction: column;
                gap: 7vh;
                width: 100%;
                padding-bottom: 10vh;

                @media screen and (max-width: 1250px) {
                    align-items: flex-start;
                    padding-bottom: 0;
                }
                @media screen and (max-width: 775px) {
                    align-items: inherit;
                }
        
                .profilePhoto{
                    display: grid;
                    place-content: center;
        
                    .circleContainer {
                        margin-inline: auto;
                        margin-top: 0.5rem;
                        width: 170px;
                        height: 170px;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center center;
                        border-radius: 50%;
                        cursor: pointer;
                        position: relative;
                        box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.274), 0 2px 3px 4px rgba(0, 0, 0, 0.06);
                        border: 3px solid #fff transparent;
                      
                          &:hover {
                              opacity: 0.8;
                          }
                      
                          svg {
                              border: 10px solid #000000;
                              box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.274), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                              width: 28px;
                              height: 28px;
                              position: absolute;
                              top: 10px;
                              right: 5px;
                              cursor: pointer;
                              background-color: white;
                              border: #181a1d;
                              padding: 4px;
                              border-radius: 50%;
                      
                            
                            }
                            &.active{
                              background-clip: content-box;
                              border: 3px solid #fff;
                            }
                    }
                }
        
                .profileInfos{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 2vh;
                    padding-bottom: 23px;

                    @media screen and (max-width: 1250px) {
                        width: 50%;
                    }
                    @media screen and (max-width: 775px) {
                        width: 90%;
                    }
                    @media screen and (max-width: 550px) {
                        width: 100%;
                    }
                }
            }

            .buttonProfile {
                justify-content: center;

                @media screen and (max-width: 1250px) {
                    justify-content: flex-end;
                }
            }
        }

        .column {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 2vh;
            height: 90%;
            width: 70%;

            @media screen and (max-width: 1250px) {
                gap: 3vh;
                width: 95%;
            }
            
            .box {
                height: 48%;

                @media screen and (max-width: 1250px) {
                    height: fit-content;
                    gap: 2vh;
                }
            }

            #editProfilePassword {
                gap: 2vh;
                
                .content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 2vh;
                    width: 100%;

                    .input {
                        width: 50%;

                        @media screen and (max-width: 775px) {
                            width: 90%;
                        }
                        @media screen and (max-width: 550px) {
                            width: 100%;
                        }

                        label {
                            padding: 4px 0;
                        }

                        input {
                            padding: 12px 0 12px 1vw;
                        }
                    }
                }
            }
            
            #editProfileApps {
                gap: 2vh;

                &.loading {
                    position: relative;
                    border-radius: 15px;
                    pointer-events: none;
                    opacity: .5;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #FFF;
                        opacity: .5;
                        z-index: 1;
                    }
                }

                .header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0;

                    .dragAndDropIcon {
                        display: flex;
                        align-items: center;
                        gap: 1vw;

                        p {
                            margin: 0;
                            font-size: 13px;
                            color: var(--grey);
                        }

                        img {
                            height: 40px;
                            width: auto;
                        }
                    }
                }
                
                .content {
                    .draggableGrid{
                        display: flex;
                        justify-content: flex-start;
                        gap: 10px;
                        align-items: center;
                        height: fit-content;
                        max-width: 100%;
                        margin: 0;
                        padding: 0 0 1vh;
                        text-align: center;
                        list-style-type: none;
                        overflow-y: hidden;
                        overflow-x: auto;
                        
                        &.dragging {
                            padding: 15px 0 20px;
                            border: 3px dotted #a9a9a9;
                        }
    
                        .blueBorderContainer {
                            display: flex;
                            gap: 10px;
                            padding: 10px;
                            border: 2px solid var(--jra-blue-pale);
                            border-radius: 10px;
                          }
        
                        .draggableItem {
                            position: relative;
                            user-select: none;
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            align-items: center;
                            height: 110px;
                            min-width: 110px;
                            max-width: 110px;
                            border-radius: 10px;

                            &:hover {
                                border: 2px solid var(--jra-blue-pale);
                                transform: scale(1) !important;
                                cursor: grab !important;
                            }
                        
                            &.dragging {
                                border: 2px solid var(--jra-blue-pale);
                                opacity: .5;
                            }

                           img {
                                height: 45px;
                                width: auto;
                                margin-top: 17px;
                            }
            
                            svg {
                                height: 35px;
                                width: 35px;
                                margin-top: 20px;
                            }
    
                            p {
                                margin: 0;
                                font-size: 11px;
                            }
                        }
                    }
                }

                .buttonProfile {
                    justify-content: space-between;
                }
            }
        }
    }
}

// modal

form.appForm {

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2vh;
        padding: 4vh 0;
        background-color: #FFF;

        .input {
            width: 50%;
        }
    }
}





