.photoPostBox {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2vh;
  
    .buttonBox {
        display: flex;
        gap: 1vw;
  
        .buttonCompressImg {
            display: flex;
            gap: 8px;
            width: fit-content;
            font-size: 10px;
  
            @media screen and (max-width: 800px) {
                font-size: 15px;
            }
  
            svg {
                height: 15px;
                width: 15px;
  
                @media screen and (max-width: 800px) {
                    height: 20px;
                    width: 20px;
                }
            }
        }
        .questionMark {
            height: 28px;
            width: 28px;
            padding: 5px;
            fill: var(--grey);
            border: 1px solid var(--grey);
            border-radius: 50%;
            cursor: pointer;
        }
    }
  
    
    .photoPost {
        position: relative;
        height: 204px;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
        border-radius: 10px;
        opacity: .8;
  
        @media screen and (max-width: 769px) {
            height: 150px;
            background-color: #f9f9f9;
            background-size: inherit;
        }
  
        &:hover {
            opacity: 1;
        }
  
        svg {
            fill: #ABABAB;
            position: absolute;
            top: -10px;
            right: -10px;
            height: 40px;
            width: 40px;
            background-color: white;
            padding: 8px;
            border-radius: 50%;
            box-shadow: 1px 1px 1px #ABABAB;
            cursor: pointer;
        }
  
        &.active {
            background-clip: content-box;
            border: 3px solid #fff;
        }
  
        &.errorInput {
            border: 1px solid var(--red);
        }
    }
  
    input[type="file"]{
        display: none;
    }
  }