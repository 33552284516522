.background {
  background-color: rgb(238, 238, 238);
  margin: 0;
  padding: 8rem 4rem 0rem 4rem;
  min-height: 100vh;

  @media screen and (max-width: 769px) {
    p {
      text-align: center;
    }
  }

  .backsquare {
    background-color: rgb(255, 255, 255);
    max-width: 100%;
    height: auto;
    padding: 4rem 4rem;
    overflow: hidden;
  }
}

@media screen and (max-width: 565px) {
  .background {
    padding-inline: 0;

    .backsquare {
      padding-inline: 2rem;
    }
  }
}

.lineContain {
  padding-bottom: 1rem;
  padding-top: 0;
  display: flex;
  flex-direction: row;
  border: none;
  max-width: 100%;
  height: auto;
  justify-content: space-between;

  @media screen and (max-width: 634px) {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
}


.tableContainer {
  .addbutton {
    margin-block: 1rem;
  }
}

@media screen and (max-width:617px) {
  .tableContainer {
    text-align: center;
  }

  .lineContain {
    flex-direction: column;

    .addbutton {
      width: 100%;
    }
  }

  .editdelete {
    position: absolute;
    bottom: 22px;
    right: 15px;
  }
}


.uiform {
  padding: 1rem 4% 4.5rem 4%;

  .content {
    display: flex;
    justify-content: space-between;
    margin-block: 3rem;

    .name {
      max-width: 50%;
    }

    .imageUpload {
      margin: 8px;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    .circleContainer {
      margin-inline: auto;
      margin-top: 0.5rem;
      width: 150px;
      height: 150px;
      background-image: url();
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.274), 0 2px 3px 4px rgba(0, 0, 0, 0.06);
      border: 3px solid #fff transparent;

      &:hover {
        opacity: 0.8;
      }

      svg {
        border: 10px solid #000000;
        box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.274), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        width: 30px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        background-color: white;
        border: #181a1d;
        padding: 4px;
        border-radius: 50%;
      }

      &.active {
        background-clip: content-box;
        border: 3px solid #fff;
      }
    }
  }

  @media screen and (max-width: 666px) {
    .content {
      flex-direction: column-reverse;

      .name {
        max-width: 100%;
        margin-top: 1rem;
      }
    }
  }
}


.checkContenair {
  padding-block: 1rem;
  display: flex;
  flex-direction: row;
  border: none;
  max-width: 100%;
  height: auto;
  gap: 1rem;
  justify-content: space-evenly;
}

@media screen and (max-width: 1315px) {
  .checkContenair {
    justify-content: space-around;
  }
}

@media screen and (max-width:666px) {
  .checkContenair {
    flex-direction: column;
    gap: 10px;
  }
}

@media screen and (max-width:370px) {
  .checkContenair {
    padding: 1em 10px;
    gap: 10px;
  }
}

.checkContenairAlone {
  padding-block: 1rem;
  display: flex;
  flex-direction: row;
  border: none;
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 1315px) {
  .checkContenairAlone {
    justify-content: space-around;
  }
}

@media screen and (max-width:666px) {
  .checkContenairAlone {
    flex-direction: column;
    gap: 15px;
  }
}

@media screen and (max-width:370px) {
  .checkContenairAlone {
    padding: 1em 10px;
    gap: 10px;
  }
}

/* summary css */
.contentSummary {
  display: flex;
  justify-content: center;
  gap: 3rem;
  padding-inline: 10%;
  padding-block: 3rem;

  @media screen and (max-width: 700px) {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 3rem;
    padding-inline: 10%;
    padding-block: 1rem;
    flex-direction: column;
  }

  .dataSummary {
    display: flex;
    flex-direction: column;

    .typoSummary {
      margin: 0;
    }

    .dividSummary {
      margin-block: 1rem;
    }

    p {
      margin-block: 0px;
    }

    .univSaleSummary {
      display: flex;
      flex-direction: column;
      padding-bottom: 1rem;
      padding-top: 5px;

      .univSaleData {
        display: flex;
        flex-direction: row;
        gap: 5px;
        max-width: 280px;
        align-items: center;

        .salepointSummary {
          display: flex;
          flex-direction: row;

          .salepointText {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .appAndRoleSummary {
      display: flex;
      flex-direction: row;
      gap: 5px;

      .appSummary {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
      }
    }

    .iconUrl {
      img {
        width: 25px;
        height: 25px;
      }

      div {
        width: 25px;
        height: 25px;
      }
    }

    .appContainer {
      max-height: 20vh;
      overflow: auto;
    }
  }
}

// modal button container
.fullSizeBox {
  position: absolute;
  bottom: 0;
  z-index: 1;

  @media (max-width:666px) and (max-height:1060px) {
    border-top: 1px solid black;
  }
}

.unvisibility {
  opacity: 0;
  z-index: 1;
  transition: opacity 0.4s ease-in;
}

.visibility {
  opacity: 1;
}

.offscreen {
  display: none;
}



.tabsContainer{
  button{
    padding: 15px 20px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 40px;
    background-color: var(--jra-blue);
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
    margin-right: 5px;
    border: 1px solid #ffffff;
    &.Mui-selected{
      color: #FFFFFFFF;
    }
    &:hover{
      color: var(--jra-blue);
      background-color: #ffffff;
      border: 1px solid var(--jra-blue);
    }
    &.selected{
      color: var(--jra-blue);
      background-color: #ffffff;
      border: 1px solid var(--jra-blue);
    }
  }
}