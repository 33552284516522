
.list{
    grid-column: 1 / span 2;
    width: 100%;
    ul{
        margin: 0;
        padding: 0;
        li{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 10px;
            margin-bottom: 25px;
            @media screen and (max-width:575px) {
                gap: 0;
                margin-bottom: 0;
            }

            .itemContainer{
                display: grid;
                grid-template-columns: 1fr 2fr 2fr 1fr;
                align-items: center;
                justify-items: center;
                .day{
                    width: 160px;
                    text-align: left;
                }
                .fkDayButton{
                    width: 50px;
                }
                .hours{
                    text-align: center;
                    grid-column: 2 /span 2;
                }
                .icon{
                    min-width: 55px;
                    min-height: 55px;
                    svg{
                        width: 100%;
                    }
                }
            }
            .penContent{
                position: relative;
                width: 50px;
            }
            .dialContent{
                position:relative;
                width: 50px;
                .dial{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    transform: translate(8px, 50%);
                }
            }
        }
    }
    svg{
        width: 20px;
        cursor: pointer;
    }
}

.button_fullwith{
    grid-column: 1 /span 2;
    text-align: center;
}