.menuButton {
	--s: 25px;
	--c: black;

	@media screen and (max-width: 650px) {
		--s: 30px;
	}

	height: var(--s);
	aspect-ratio: 1;
	border: none;
	padding: 0;
	border-inline: calc(var(--s) / 2) solid #0000;
	box-sizing: content-box;
	--_g1: linear-gradient(var(--c) 20%, #0000 0 80%, var(--c) 0) no-repeat
		content-box border-box;
	--_g2: radial-gradient(circle closest-side at 50% 12.5%, var(--c) 95%, #0000)
		repeat-y content-box border-box;
	background: var(--_g2) left var(--_p, 0px) top,
		var(--_g1) left calc(var(--s) / 10 + var(--_p, 0px)) top,
		var(--_g2) right var(--_p, 0px) top,
		var(--_g1) right calc(var(--s) / 10 + var(--_p, 0px)) top;
	background-size: 20% 80%, 43% 100%;
	position: relative;
	clip-path: inset(0 25%);
	-webkit-mask: linear-gradient(90deg, #0000, #000 25% 75%, #0000);
	cursor: pointer;
	transition: background-position 0.3s var(--_s, 0.3s),
		clip-path 0s var(--_s, 0.6s);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.menuButton:before,
.menuButton:after {
	content: "";
	position: absolute;
	border-radius: var(--s);
	inset: 40% 0;
	background: var(--c);
	transition: transform 0.3s calc(0.3s - var(--_s, 0.3s));
}

.menuButton:checked {
	clip-path: inset(0);
	--_p: calc(-1 * var(--s));
	--_s: 0s;
}

.menuButton:checked:before {
  	transform: rotate(45deg);
}

.menuButton:checked:after {
  	transform: rotate(-45deg);
}

.menuButton:focus-visible {
	clip-path: none;
	-webkit-mask: none;
	border: none;
	outline: 2px solid var(--c);
	outline-offset: 5px;
}

.nav {
  	z-index: 2 !important;

	.box {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: center;
		padding-top: 75px;
		width: 300px;
		height: 100vh;
		@media screen and (max-width: 650px) {
		width: 370px;
		}
 	}

	ul.list {
		@media screen and (max-width: 1665px) {
			padding-top: 20px;
		}
		@media screen and (max-width: 1365px) {
			padding-top: 30px;
		}
		@media screen and (max-width: 1200px) {
			padding-top: 8px;
		}

		hr {
			margin: 10px 0;
		}

		.active {
			svg {
				fill: #008ec8 !important;
			}

			img {
				filter: none;
			}

			a,
			p {
				color: #008ec8 !important;
				font-weight: 700 !important;
			}
		}

		li.listitem {
			svg {
				height: 30px;
				width: 30px;
				margin-left: 5px;
				fill: #6d6d6d;
			}

			a {
				width: 100%;
				color: #6d6d6d;
				text-decoration: none;
			}

			&:hover {
				svg {
				fill: #008ec8;
				}

				img {
				filter: none;
				}

				a {
				color: #008ec8;
				font-weight: 700;
				}
			}
		}

		// submenu gestion
		.submenuButton {

			svg {
				margin-left: 8px;
			}
			// header
			.textIcon {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;

				p {
					margin: 5px 0;
				}
			}
		}

		.submenu {
			margin-left: 27px;
			border-left: 1px solid var(--grey);

			.listitem {
				font-size: 15px;
			}
		}

		// externe links
		.boxNavOut {
			.listitem {
				> a > div {
				justify-content: space-between;

				> div {
					display: flex;
					gap: 29px;
					align-items: center;

					img.iconNavOut {
					height: 27px;
					opacity: 0.6;
					}
				}
				}
			}
		}

		.nav {
			z-index: 0 !important;

			.box {
				padding-top: 100px;
				width: 300px;
			}

			.list {
				.listitem {
				@media screen and (max-width: 650px) {
					font-size: 18px;
				}

				svg {
					fill: #6d6d6d;
					height: 30px;
					width: 30px;
					@media screen and (max-width: 650px) {
					height: 40px;
					width: 40px;
					}
				}

				svg {
					height: 20px;
				}
				}
			}
		}

		// common style
		.listitem,
		.submenuButton,
		.boxNavOut {
			@media screen and (max-width: 650px) {
				font-size: 20px;
			}
		}
		.listitem svg,
		.submenuButton svg,
		.boxNavOut img {
			@media screen and (max-width: 650px) {
				height: 40px !important;
				width: 40px !important;
			}
		}
		.boxNavOut svg {
			@media screen and (max-width: 650px) {
				height: 30px !important;
				width: 30px !important;
			}
		}
	}

	.iconUrl {
		justify-content: center;

		img {
			width: 40px;
			height: auto;

			@media screen and (max-width: 650px) {
				height: 40px;
				width: 40px;
			}
		}

		div {
			width: 40px;
			height: 40px;

			svg {
				width: 28px !important;
				height: 28px !important;
				margin-left: 5px;
			}
		}
	}
}
