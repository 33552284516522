.breakTitle{
    margin: 20px 0;
    width: 100%;
}

.containerVideo {
	display: grid;
	grid-template-columns: 2fr 1fr;
	align-items: flex-start;
	padding: 1rem;
	gap: 5px;
	background: var(--lightgrey);;
	border-radius: 10px;
    .mainVideo {
        background: #fff;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        padding: 10px;
        max-width: 100%;
        video {
            width: 100%;
            height: auto;
            border-radius: 10px;
            
            &::-webkit-media-controls-progress-bar {
                background-color: rgb(91, 95, 98); 
            }
            &::-webkit-media-controls-buffer-bar {
                background-color: rgb(221, 221, 221); 
            }
        }
        .titleVideo {
            color: #333;
            font-size: 15px;
            text-align: start;
            margin: 10px 0;
        }
    }
    .videoList {
        background: #fff;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        max-height: 600px;
        @media screen and (max-width: 1400px){        
            max-height: 415px;
        }
        overflow-y: auto;
        scrollbar-width: thin;
        .vid{
            display: flex;
            align-items: center;
            gap: 15px;
            background: #f7f7f7;
            border-radius: 10px;
            margin: 10px;
            padding: 10px;
            border: 1px solid rgba(0, 0, 0,.1);
            cursor: pointer;
            &:hover {
                background: #eee;
            }
            &.active {
                background: #068ce625;
                .titleVideo{
                    color: #0788d3;
                }
            }
            .titleVideo{
                color: #333;
                font-size: 10px;
                white-space: nowrap;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &:hover{
                    white-space: normal; 
                    word-wrap: break-word;
                    text-align: left;
                }
            }
            video{
                width: 100px;
                border-radius: 10px;
            }
        }
    }
    @media screen and (max-width: 991px){
        grid-template-columns: 1.5fr 1fr;
        padding: 10px;
    }

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
    }
}

.mainButtonContainer{
    margin: 20px 0;
    text-align: center;
    width: 100%;
}
  
.uiform {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding: 1rem 2rem 1rem 2rem;
    @media screen and (max-width: 650px) {
        padding: 1rem;
    }
}